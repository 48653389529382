import box from './menu/box';



function App() {
  return (
    <div>
      {box()}
    </div>
  );
}

export default App;
