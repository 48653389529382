import './box.css';
import vkIcon from './photo/vk.png';
import telegramIcon from './photo/telegram.png';
import projectIcon from './photo/coding.png';

function Box() {
  return (
    <div className="box-container">
      <div className="stars"></div> 
      
      <div className="glow-bar top"></div>

      <div className="box">
        <div>
          <h2>Xallse | | Юрий</h2>
        </div>
        <div>
          <p>Разрабочик (junior) и студент</p>
        </div>

        <div className="social-icons">
          <div>
            <a href="https://vk.com/vkkruglov">
              <img src={vkIcon} alt="VK Icon" className="icon" /> VK
            </a>
          </div>
          <div>
            <a href="https://t.me/xallse">
              <img src={telegramIcon} alt="Telegram Icon" className="icon" /> Telegram
            </a>
          </div>
          <div>
            <a href="https://t.me/xallseproject">
              <img src={projectIcon} alt="Projects Icon" className="icon" /> Мои проекты
            </a>
          </div>
        </div>
      </div>

      <div className="glow-bar bottom"></div>
    </div>
  );
}

export default Box;


